import React, {useCallback, useState} from "react";
import {Card, LoadingOverlay, Pagination, ScrollArea, Table, useMantineColorScheme} from "@mantine/core";
import {IBooking} from "../../../api/booking";
import ExpandRow from "./ExpanRow";
import classes from "./orders.module.css";
import ChangePaymentType from "./ChangePaymentType";

type TProps = {
    isFetching: boolean,
    data: any,
    page: number,
    totalPages: number,
    type: 'cash-box_orders' | 'internet_orders',
    setPage: (page: number) => any;
}
const initialModalState = {
    opened: false,
    booking: null
};

const OrdersTable = (props: TProps) => {
    const {data, isFetching, page, totalPages, setPage, type} = props;
    const [modalState, setModalState] = useState<{
        opened: boolean,
        booking: IBooking | null
    }>(initialModalState);
    const colorScheme = useMantineColorScheme();

    const closeChangeType = useCallback(() => {
        setModalState(initialModalState);
    }, []);

    function onClickChangeType (booking: IBooking) {
        setModalState({
            opened: true,
            booking
        });
    }
    return (
        <Card p={0} my={20}>
            <LoadingOverlay visible={isFetching}/>
            <ScrollArea>
                <Table verticalSpacing={"sm"} highlightOnHover={true}>
                    <Table.Thead bg={colorScheme.colorScheme === "dark" ? "dark.4" : "gray.0"} h={50}>
                        <Table.Tr>
                            <Table.Th>
                                №
                            </Table.Th>
                            <Table.Th>
                                Дата заказа
                            </Table.Th>
                            <Table.Th>
                                Мероприятие
                            </Table.Th>
                            <Table.Th>
                                Автор Заказа
                            </Table.Th>
                            <Table.Th w={120}>
                                Статус
                            </Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {(data?.results || []).map((booking: IBooking) => <ExpandRow key={booking.id}
                                                                                     booking={booking}
                                                                                     type={type}
                                                                                     onClickChangeType={onClickChangeType}
                        />)}
                    </Table.Tbody>
                </Table>
            </ScrollArea>
            <div className={classes.paginationContainer}>
                <Pagination
                    value={page}
                    total={totalPages}
                    onChange={setPage}
                />
            </div>
            <ChangePaymentType
                opened={modalState.opened}
                onClose={closeChangeType}
                booking={modalState.booking}
            />
        </Card>
    )
}

export default OrdersTable;