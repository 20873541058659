import {useRef} from "react";
import {Paper, Text, Box, Table, Center, LoadingOverlay} from "@mantine/core";
import {useMantineColorScheme} from "@mantine/core";
import dayjs from "dayjs";
import {useReactToPrint} from "react-to-print";
import {useTranslation} from "react-i18next";
import {TTicketSalesResponse, useTicketSalesQuery} from "../../../api/analytics";
import {useAppSelector} from "../../../app/hooks";
import ReportHeader from "../ReportActions";
import {formatAmount, formatQuantity} from "../report_utils";
import ReportPrint from "../ReportPrint";

const locales = {
    box_office: "Box Office",
    internet: "Internet",
    invoice: "Invoice"
}
const TicketSales = () => {
    const reportRef = useRef(null);
    const {t} = useTranslation();
    const selectedEvent = useAppSelector(state => state.reports.selectedEvent);
    const date = useAppSelector(state => state.reports.date);

    const {data: report, isFetching} = useTicketSalesQuery({
        event: selectedEvent,
        date_lte: dayjs(date).format('YYYY-MM-DD')
    }, {
        refetchOnMountOrArgChange: true,
        skip: !selectedEvent
    });

    const handlePrint = useReactToPrint({
        content: () => reportRef.current,
        copyStyles: true,
        pageStyle: `@page {
           margin: 10mm;
           size: A4 landscape;
        }`
    });

    return (
        <Box pos={"relative"}>
            <Paper p={10}>
                <ReportHeader
                    showEventSelect
                    showPeriodSelect
                    dateType={"default"}
                />
            </Paper>

            {selectedEvent && (
                <Paper mt={10} pt={5} p={20} pos={"relative"}
                       ref={reportRef}>
                    <ReportPrint handlePrint={handlePrint}/>
                    <Box ta={"center"}>
                        <Text size={"30px"} pb={20}>{t("reports.ticket-sales.report")}</Text>
                        <Text size={"lg"}> {t("reports.ticket-sales.textHead")} {t("reports.ticket-sales.for")} <Text
                            fw={600} span c={"blue.9"}> {dayjs(date).format("DD-MM-YYYY")}
                        </Text>
                        </Text>
                        <Text size={"lg"}>{t("reports.ticket-sales.textHead2")}
                            <br/>
                            <Text fw={600} c={"blue.9"}
                                  span> {report?.event.name} {dayjs(report?.event.start).format("DD.MM.YYYY HH:mm")}</Text>
                        </Text>
                    </Box>
                    <Center>
                        <Box my={20}>
                            <TotalReport data={report}/>
                            <Text mt={20}>Sef casei de bilete ____________________________</Text>
                        </Box>
                    </Center>
                    <Box mt={20} p={20} pos={"relative"}>
                        <Text mb={20} ta={"center"} size={"30px"} fw={300}>{t("reports.ticket-sales.annexe")}</Text>
                        <Box>
                            <Box>
                                <Text fw={300}>{t("reports.ticket-sales.textHead")}</Text>
                                <Text fw={300} size={"md"}>{t("reports.ticket-sales.textHead2")} <Text fw={700}
                                                                                                       c={"blue.9"}
                                                                                                       span>
                                    {report?.event.name}
                                    <Text span fw={300}>
                                        <Text span c={"blue.9"}
                                              fw={700}> {dayjs(report?.event.start).format("DD.MM.YYYY HH:mm")}
                                        </Text>
                                    </Text>
                                </Text>
                                </Text>
                            </Box>
                        </Box>
                        <AnnexTable data={report}/>
                    </Box>
                </Paper>
            )}
            <LoadingOverlay visible={isFetching}/>
        </Box>
    );
};

export default TicketSales;


const AnnexTable = (props: { data?: TTicketSalesResponse }) => {
    const {t} = useTranslation();
    const {data = {rows: []}} = props;
    const {colorScheme} = useMantineColorScheme();
    const totals = data.rows.reduce((acc, curr) => {
        acc = {
            offered_quantity: acc.offered_quantity += curr.offered_quantity,
            offered_amount: acc.offered_amount += curr.offered_amount,
            sold_quantity: acc.sold_quantity += curr.sold_quantity,
            sold_amount: acc.sold_amount += curr.sold_amount,
            refunded_quantity: acc.refunded_quantity += curr.refunded_quantity,
            refunded_amount: acc.refunded_amount += curr.refunded_amount,
            unsold_quantity: acc.unsold_quantity += curr.unsold_quantity,
            unsold_amount: acc.unsold_amount += curr.unsold_amount,
            revenue: acc.revenue += curr.revenue
        };
        return acc;
    }, {
        offered_quantity: 0,
        offered_amount: 0,
        sold_quantity: 0,
        sold_amount: 0,
        refunded_quantity: 0,
        refunded_amount: 0,
        unsold_quantity: 0,
        unsold_amount: 0,
        revenue: 0
    });
    return (
        <Table withColumnBorders={true} withTableBorder={true} striped={"even"}
               highlightOnHover
        >
            <Table.Thead bg={colorScheme === "dark" ? "dark.8" : "gray.0"}>
                <Table.Tr>
                    <Table.Th rowSpan={2}>
                        {t("reports.table.seatNames")}
                    </Table.Th>
                    <Table.Th colSpan={3}>
                        {t("reports.table.salesTickets")}
                    </Table.Th>
                    <Table.Th colSpan={2}>
                        {t("reports.table.ticketsSold")}
                    </Table.Th>
                    <Table.Th colSpan={2}>
                        {t("reports.table.ticketsRefunded")}
                    </Table.Th>
                    <Table.Th colSpan={2}>
                        {t("reports.table.ticketsUnSold")}
                    </Table.Th>
                    <Table.Th rowSpan={2}>
                        {t("reports.table.income")}
                    </Table.Th>
                </Table.Tr>
                <Table.Tr>
                    <Table.Th>
                        {t("reports.table.priceTicket")}
                    </Table.Th>
                    <Table.Th>
                        {t("reports.table.quantity")}
                    </Table.Th>
                    <Table.Th>
                        {t("reports.table.sum")}
                    </Table.Th>
                    <Table.Th>
                        {t("reports.table.quantity")}
                    </Table.Th>
                    <Table.Th>
                        {t("reports.table.sum")}
                    </Table.Th>
                    <Table.Th>
                        {t("reports.table.quantity")}
                    </Table.Th>
                    <Table.Th>
                        {t("reports.table.sum")}
                    </Table.Th>
                    <Table.Th>
                        {t("reports.table.quantity")}
                    </Table.Th>
                    <Table.Th>
                        {t("reports.table.sum")}
                    </Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {data.rows.map((row, i) => {
                    return (
                        <Table.Tr key={i}>
                            <Table.Td>
                                {row.place}
                            </Table.Td>
                            <Table.Td align={"center"}>
                                {formatAmount(row.zone__price)}
                            </Table.Td>
                            <Table.Td align={"right"}>
                                {formatQuantity(row.offered_quantity)}
                            </Table.Td>
                            <Table.Td align={"center"}>
                                {formatAmount(row.offered_amount)}
                            </Table.Td>
                            <Table.Td align={"right"}>
                                {formatAmount(row.sold_quantity)}
                            </Table.Td>
                            <Table.Td align={"center"}>
                                {formatAmount(row.sold_amount)}
                            </Table.Td>
                            <Table.Td align={"right"}>
                                <Text size={"sm"}
                                      fw={row.refunded_quantity > 0 ? 600 : 400}>{formatQuantity(row.refunded_quantity)}</Text>
                            </Table.Td>
                            <Table.Td align={"center"}>
                                <Text size={"sm"} fw={row.refunded_amount > 0 ? 600 : 400}>
                                    {formatAmount(row.refunded_amount)}
                                </Text>
                            </Table.Td>
                            <Table.Td align={"right"}>
                                {formatQuantity(row.unsold_quantity)}
                            </Table.Td>
                            <Table.Td align={"center"}>
                                {formatAmount(row.unsold_amount)}
                            </Table.Td>
                            <Table.Td align={"center"}>
                                {formatAmount(row.revenue)}
                            </Table.Td>
                        </Table.Tr>
                    );
                })}
            </Table.Tbody>
            <Table.Tfoot bg={colorScheme === "dark" ? "dark.8" : "gray.0"} style={{borderTop: "1px solid lightgray"}}>
                <Table.Tr>
                    <Table.Th>{t("reports.table.total")}</Table.Th>
                    <Table.Th></Table.Th>
                    <Table.Th style={{textAlign: "right"}}>{formatQuantity(totals.offered_quantity)}</Table.Th>
                    <Table.Th style={{textAlign: "center"}}>{formatAmount(totals.offered_amount)}</Table.Th>
                    <Table.Th style={{textAlign: "right"}}>{formatQuantity(totals.sold_quantity)}</Table.Th>
                    <Table.Th style={{textAlign: "center"}}>{formatAmount(totals.sold_amount)}</Table.Th>
                    <Table.Th style={{textAlign: "right"}}>{formatQuantity(totals.refunded_quantity)}</Table.Th>
                    <Table.Th style={{textAlign: "center"}}>{formatAmount(totals.refunded_amount)}</Table.Th>
                    <Table.Th style={{textAlign: "right"}}>{formatQuantity(totals.unsold_quantity)}</Table.Th>
                    <Table.Th style={{textAlign: "center"}}>{formatAmount(totals.unsold_amount)}</Table.Th>
                    <Table.Th style={{textAlign: "center"}}>{formatAmount(totals.revenue)}</Table.Th>
                </Table.Tr>
            </Table.Tfoot>
        </Table>
    );
};


const TotalReport = (props: { data?: TTicketSalesResponse }) => {
    const {data = {header: []}} = props;
    const {colorScheme} = useMantineColorScheme();
    const {t} = useTranslation();
    const totals = data.header.reduce((acc, curr) => {
        acc = {
            sold_quantity: acc.sold_quantity += curr.sold_quantity,
            sold_amount: acc.sold_amount += curr.sold_amount,
            refunded_amount: acc.refunded_amount += curr.refunded_amount,
            refunded_quantity: acc.refunded_quantity += curr.refunded_quantity,
        }
        return acc;
    }, {
        sold_quantity: 0,
        sold_amount: 0,
        refunded_amount: 0,
        refunded_quantity: 0,
    })
    return (
        <Table withTableBorder={true} withColumnBorders={true} striped={"even"} highlightOnHover={true}>
            <Table.Thead bg={colorScheme === "dark" ? "dark.8" : "gray.0"}>
                <Table.Tr>
                    <Table.Th rowSpan={2}>
                        {t("reports.table.name")}
                    </Table.Th>
                    <Table.Th colSpan={2}>
                        {t("reports.table.sold")}
                    </Table.Th>
                    <Table.Th colSpan={2}>
                        {t("reports.table.refunded")}
                    </Table.Th>
                    <Table.Th colSpan={2}>
                        {t("reports.table.net")}
                    </Table.Th>
                </Table.Tr>
                <Table.Tr>
                    <Table.Th>
                        {t("reports.table.nrSeats")}
                    </Table.Th>
                    <Table.Th>
                        {t("reports.table.sum")}
                    </Table.Th>
                    <Table.Th>
                        {t("reports.table.nrSeats")}
                    </Table.Th>
                    <Table.Th>
                        {t("reports.table.sum")}
                    </Table.Th>
                    <Table.Th>
                        {t("reports.table.nrSeats")}
                    </Table.Th>
                    <Table.Th>
                        {t("reports.table.sum")}
                    </Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {data.header.map((row, i) => {
                    return (
                        <Table.Tr key={i}>
                            <Table.Td>
                                {locales[row.source as keyof typeof locales]}
                            </Table.Td>
                            <Table.Td align={"right"}>
                                {formatQuantity(row.sold_quantity)}
                            </Table.Td>
                            <Table.Td align={"center"}>
                                {formatAmount(row.sold_amount)}
                            </Table.Td>
                            <Table.Td align={"right"}>
                                <Text size={"sm"}
                                      fw={row.refunded_quantity > 0 ? 600 : 400}>{formatQuantity(row.refunded_quantity)}</Text>
                            </Table.Td>
                            <Table.Td align={"center"}>
                                <Text size={"sm"} fw={row.refunded_amount > 0 ? 600 : 400}>
                                    {formatAmount(row.refunded_amount)}
                                </Text>
                            </Table.Td>

                            {/*net column*/}
                            <Table.Td align={"right"}>
                                <Text size={"sm"}>{formatQuantity(row.sold_quantity - row.refunded_quantity)}</Text>
                            </Table.Td>
                            <Table.Td align={"center"}>
                                <Text size={"sm"}>
                                    {formatAmount(row.sold_amount - row.refunded_amount)}
                                </Text>
                            </Table.Td>
                        </Table.Tr>
                    );
                })}

                {/*totals*/}

                <Table.Tr>
                    <Table.Td>
                        {t("reports.table.total")}
                    </Table.Td>
                    <Table.Td align={"right"}>
                        {formatQuantity(totals.sold_quantity)}
                    </Table.Td>
                    <Table.Td align={"center"}>
                        {formatAmount(totals.sold_amount)}
                    </Table.Td>
                    <Table.Td align={"right"}>
                        <Text size={"sm"}
                              fw={totals.refunded_quantity > 0 ? 600 : 400}>{formatQuantity(totals.refunded_quantity)}</Text>
                    </Table.Td>
                    <Table.Td align={"center"}>
                        <Text size={"sm"} fw={totals.refunded_amount > 0 ? 600 : 400}>
                            {formatAmount(totals.refunded_amount)}
                        </Text>
                    </Table.Td>
                    <Table.Td align={"right"}>
                        {formatQuantity(totals.sold_quantity - totals.refunded_quantity)}
                    </Table.Td>
                    <Table.Td align={"center"}>
                        {formatAmount(totals.sold_amount - totals.refunded_amount)}
                    </Table.Td>
                </Table.Tr>
            </Table.Tbody>
        </Table>
    );
};
