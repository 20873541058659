import { api } from "../app/api";
import { IUser } from "./user";
import {IEvent} from "./event";
import {IBoxOffice} from "./box-office";


export interface IReceipt {
    id: number,
    customer: IUser,
    cashier: IUser,
    event: IEvent,
    box_office: IBoxOffice,
    timestamp: string,
    amount: string,
    payment_type: 'cash' | 'card',
    description?: string,
    sales_session: number,
    booking: number,

    //audit
    author?: IUser;
    modifier?: IUser;
    modified?: Date;
    created?: Date,
}

interface IQueryResponse {
    count: number;
    next: any;
    previous: any;
    results: IReceipt[];
}

interface IQueryParams {
    event?: number;
    customer?: number;
    timestamp_gte?: string,
    timestamp_lte?: string,
    box_office?: number,
    payment_type?: 'cash' | 'card' | 'bank_transfer' | 'internet'
    description?: string,

    ordering?: string;
    page?: number;
    page_size?: number;
}

export const ReceiptApi = api.injectEndpoints({
    endpoints: (build) => ({
        getReceipts: build.query<IQueryResponse, IQueryParams>({
            query: (params) => ({
                url: `/sales/admin/receipts/`,
                params
            }),
            providesTags: (result, error, tag) => [{ type: "Receipt", tag }]
        }),
        changeTypeReceipt: build.mutation<IReceipt, { payment_type: 'cash' | 'card', id: number }>({
            query: (payload) => ({
                url: `/sales/admin/receipts/${payload.id}/change-type/`,
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{ type: "Receipt" }, {type: "Tickets"}]
        }),
        readReceipt: build.query<any, { id: number }>({
            query: (params) => ({
                url: `/sales/admin/receipts/${params.id}/`,
                method: "GET"
            }),
            providesTags: (result, error, tag) => [{ type: "Receipt", tag }]
        }),
    })
});

export const {
    useReadReceiptQuery,
    useLazyReadReceiptQuery,
    useGetReceiptsQuery,
    useChangeTypeReceiptMutation
} = ReceiptApi;
