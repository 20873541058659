import {useRef} from "react";
import {Box, Flex, LoadingOverlay, Paper, Table, Text} from "@mantine/core";
import {useMantineColorScheme} from "@mantine/core";
import {useReactToPrint} from "react-to-print";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {TZoneSalesResponse, useZoneSalesQuery} from "../../../api/analytics";
import {useAppSelector} from "../../../app/hooks";
import ReportHeader from "../ReportActions";
import {formatAmount, formatQuantity} from "../report_utils";
import ReportPrint from "../ReportPrint";

const ZoneSales = () => {
    const {t} = useTranslation();
    const reportRef = useRef(null);
    const selectedEvent = useAppSelector(state => state.reports.selectedEvent);
    const date = useAppSelector(state => state.reports.date);
    const {data: report, isFetching} = useZoneSalesQuery({
        event: selectedEvent,
        date_lte: dayjs(date).format('YYYY-MM-DD'),
    }, {
        skip: !selectedEvent,
        refetchOnMountOrArgChange: true
    });

    const handlePrint = useReactToPrint({
        content: () => reportRef.current,
        pageStyle: `@page {
           margin: 10mm;
           size: A4 landscape;
        }`
    });

    return (
        <Box pos={"relative"}>
            <Paper p={10}>
                <ReportHeader
                    showEventSelect
                    dateType={"default"}
                    showPeriodSelect
                />
            </Paper>
            {selectedEvent && (
                <Paper mt={10} pt={5} p={20} ref={reportRef} pos={"relative"}>
                    <ReportPrint handlePrint={handlePrint}/>
                    <Box ta={"center"} mb={30}>
                        <Text size={"30px"} pb={20}>{t("reports.ticket-sales.report")}</Text>
                        <Text size={"lg"}> {t("reports.prices.text")} {t("reports.ticket-sales.for")}
                            <Text fw={600}
                                  span
                                  c={"blue.9"}> {dayjs(date).format("DD-MM-YYYY")}
                            </Text>
                        </Text>
                        <Text size={"lg"}>{t("reports.ticket-sales.textHead2")}
                            <br/>
                            <Text fw={600} c={"blue.9"}
                                  span> {report?.event.name} {dayjs(report?.event.start).format("DD.MM.YYYY HH:mm")}</Text>
                        </Text>
                    </Box>
                    <ZoneSalesTable
                        data={report}
                    />
                    <Box mt={30}>
                        <Flex justify={"space-between"}>
                            <Text>Contabil sef ____________________</Text>
                            <Text>Sef Casei de bilete ____________________</Text>
                        </Flex>
                    </Box>
                </Paper>
            )}
            <LoadingOverlay visible={isFetching}/>
        </Box>
    );
};

export default ZoneSales;


const ZoneSalesTable = (props: { data?: TZoneSalesResponse }) => {
    const {t} = useTranslation();
    const {data = {rows: []}} = props;
    const {colorScheme} = useMantineColorScheme();

    const totals = data.rows.reduce((acc, curr) => {
        return {
            offered_quantity: acc.offered_quantity += curr.offered_quantity,
            offered_amount: acc.offered_amount += curr.offered_amount,
            sold_quantity: acc.sold_quantity += curr.sold_quantity,
            sold_cash_amount: acc.sold_cash_amount += curr.sold_cash_amount,
            sold_card_amount: acc.sold_card_amount += curr.sold_card_amount,
            sold_bank_amount: acc.sold_bank_amount += curr.sold_bank_amount,
            sold_internet_amount: acc.sold_internet_amount += curr.sold_internet_amount,
            refunded_quantity: acc.refunded_quantity += curr.refunded_quantity,
            refunded_amount: acc.refunded_amount += curr.refunded_amount,
            unsold_quantity: acc.unsold_quantity += curr.unsold_quantity,
            unsold_amount: acc.unsold_amount += curr.unsold_amount,
            revenue: acc.revenue += curr.revenue
        };
    }, {
        offered_quantity: 0,
        offered_amount: 0,
        sold_quantity: 0,
        sold_cash_amount: 0,
        sold_card_amount: 0,
        sold_bank_amount: 0,
        sold_internet_amount: 0,
        refunded_quantity: 0,
        refunded_amount: 0,
        unsold_quantity: 0,
        unsold_amount: 0,
        revenue: 0
    });

    return (
        <Table withTableBorder withColumnBorders withRowBorders striped={"even"} highlightOnHover>
            <Table.Thead bg={colorScheme === "dark" ? "dark.8" : "gray.0"}>
                <Table.Tr>
                    <Table.Th rowSpan={2}>{t("reports.table.price")}</Table.Th>
                    <Table.Th colSpan={2}>{t("reports.table.salesTickets")}</Table.Th>
                    <Table.Th colSpan={5}>{t("reports.table.ticketsSold")}</Table.Th>
                    <Table.Th colSpan={2}>{t("reports.table.ticketsRefunded")}</Table.Th>
                    <Table.Th colSpan={2}>{t("reports.table.ticketsUnSold")}</Table.Th>
                    <Table.Th rowSpan={2}>{t("reports.table.income")}</Table.Th>
                </Table.Tr>
                <Table.Tr>
                    <Table.Th>{t("reports.table.quantity")}</Table.Th>
                    <Table.Th>{t("reports.table.sum")}</Table.Th>
                    <Table.Th>{t("reports.table.quantity")}</Table.Th>
                    <Table.Th>{t("reports.table.payedCash")}</Table.Th>
                    <Table.Th>{t("reports.table.payedCard")}</Table.Th>
                    <Table.Th>{t("reports.table.payedBank")}</Table.Th>
                    <Table.Th>{t("reports.table.payedInternet")}</Table.Th>
                    <Table.Th>{t("reports.table.quantity")}</Table.Th>
                    <Table.Th>{t("reports.table.sum")}</Table.Th>
                    <Table.Th>{t("reports.table.quantity")}</Table.Th>
                    <Table.Th>{t("reports.table.sum")}</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {data.rows.map((row, i) => {
                    return (
                        <Table.Tr key={i}>
                            <Table.Td>
                                {row["zone__price"] || "-"}
                            </Table.Td>
                            <Table.Td align={"right"}>
                                {formatQuantity(row.offered_quantity)}
                            </Table.Td>
                            <Table.Td align={"center"}>
                                {formatAmount(row.offered_amount)}
                            </Table.Td>
                            <Table.Td align={"right"}>
                                {formatQuantity(row.sold_quantity)}
                            </Table.Td>
                            <Table.Td align={"center"}>
                                {formatAmount(row.sold_cash_amount)}
                            </Table.Td>
                            <Table.Td align={"center"}>
                                {formatAmount(row.sold_card_amount)}
                            </Table.Td>
                            <Table.Td align={"center"}>
                                {formatAmount(row.sold_bank_amount)}
                            </Table.Td>
                            <Table.Td align={"center"}>
                                {formatAmount(row.sold_internet_amount)}
                            </Table.Td>
                            <Table.Td align={"right"}>
                                <Text size={"sm"}
                                      fw={row.refunded_quantity > 0 ? 600 : 400}>{formatQuantity(row.refunded_quantity)}</Text>
                            </Table.Td>
                            <Table.Td align={"center"}>
                                <Text size={"sm"} fw={row.refunded_amount > 0 ? 600 : 400}>
                                    {formatAmount(row.refunded_amount)}
                                </Text>
                            </Table.Td>
                            <Table.Td align={"right"}>
                                {formatQuantity(row.unsold_quantity)}
                            </Table.Td>
                            <Table.Td align={"center"}>
                                {formatAmount(row.unsold_amount)}
                            </Table.Td>
                            <Table.Td align={"center"}>
                                {formatAmount(row.revenue)}
                            </Table.Td>
                        </Table.Tr>
                    );
                })}
            </Table.Tbody>
            <Table.Tfoot bg={colorScheme === "dark" ? "dark.8" : "gray.0"} style={{borderTop: "1px solid lightgray"}}>
                <Table.Tr>
                    <Table.Th>
                    </Table.Th>
                    <Table.Th style={{textAlign: "right"}}>
                        {formatQuantity(totals.offered_quantity)}
                    </Table.Th>
                    <Table.Th style={{textAlign: "center"}}>
                        {formatAmount(totals.offered_amount)}
                    </Table.Th>
                    <Table.Th style={{textAlign: "right"}}>
                        {formatQuantity(totals.sold_quantity)}
                    </Table.Th>
                    <Table.Th style={{textAlign: "center"}}>
                        {formatAmount(totals.sold_cash_amount)}
                    </Table.Th>
                    <Table.Th style={{textAlign: "center"}}>
                        {formatAmount(totals.sold_card_amount)}
                    </Table.Th>
                    <Table.Th style={{textAlign: "center"}}>
                        {formatAmount(totals.sold_bank_amount)}
                    </Table.Th>
                    <Table.Th style={{textAlign: "center"}}>
                        {formatAmount(totals.sold_internet_amount)}
                    </Table.Th>
                    <Table.Th style={{textAlign: "right"}}>
                        {formatQuantity(totals.refunded_quantity)}
                    </Table.Th>
                    <Table.Th style={{textAlign: "center"}}>
                        {formatAmount(totals.refunded_amount)}
                    </Table.Th>
                    <Table.Th style={{textAlign: "right"}}>
                        {formatQuantity(totals.unsold_quantity)}
                    </Table.Th>
                    <Table.Th style={{textAlign: "center"}}>
                        {formatAmount(totals.unsold_amount)}
                    </Table.Th>
                    <Table.Th style={{textAlign: "center"}}>
                        {formatAmount(totals.revenue)}
                    </Table.Th>
                </Table.Tr>
            </Table.Tfoot>
        </Table>
    );
};
