import { Box, LoadingOverlay, Paper, Table, Text, useMantineColorScheme } from "@mantine/core";
import ReportHeader from "../ReportActions";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { useAppSelector } from "../../../app/hooks";
import { TPriceListResponse, usePriceListQuery } from "../../../api/analytics";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { formatAmount, formatQuantity } from "../report_utils";
import ReportPrint from "../ReportPrint";
import { groupBy } from "lodash";
import React from "react";

const PriceList = () => {
    const reportRef = useRef(null);
    const selectedEvent = useAppSelector(state => state.reports.selectedEvent);
    const { t } = useTranslation();
    const { data: report, isFetching } = usePriceListQuery({
        event: selectedEvent
    }, {
        refetchOnMountOrArgChange: true,
        skip: !selectedEvent
    });

    const handlePrint = useReactToPrint({
        content: () => reportRef.current,
        copyStyles: true,
        pageStyle: `@page {
           margin: 10mm;
           size: A4 landscape;
        }`
    });
    return (
        <Box pos={"relative"}>
            <Paper p={10}>
                <ReportHeader
                    showEventSelect
                    dateType={"default"}
                />
            </Paper>
            {selectedEvent && (
                <Paper mt={10} pt={5} p={20} pos={"relative"}
                    ref={reportRef}>
                    <ReportPrint handlePrint={handlePrint} />
                    <Box ta={"center"} pb={20}>
                        <Text size={"30px"} pb={10}>{t("reports.price-list.tariff")}</Text>
                        <Text size={"lg"}> {t("reports.price-list.text2")}
                        </Text>
                        <Text size={"lg"}>{t("reports.ticket-sales.textHead2")}
                            <br />
                            <Text fw={600} c={"blue.9"}
                                span> {report?.event.name} {dayjs(report?.event.start).format("DD.MM.YYYY HH:mm")}</Text>
                        </Text>
                    </Box>
                    <PriceListTable data={report} />
                    <Box mt={80}>
                        <Text>Director general _______________________</Text>
                    </Box>
                </Paper>
            )}
            <LoadingOverlay visible={isFetching} />
        </Box>
    );
};

export default PriceList;

const PriceListTable = (props: { data?: TPriceListResponse }) => {
    const { t } = useTranslation();
    const { data = { rows: [] } } = props;
    const { colorScheme } = useMantineColorScheme();

    const totals = data.rows.reduce((acc, curr) => {
        acc = {
            quantity: acc.quantity += curr.quantity,
            sum: acc.sum += curr.sum,
        };
        return acc;
    }, {
        quantity: 0,
        sum: 0
    });
    const grouped = groupBy(data.rows, "hall_part");

    return (
        <Table withColumnBorders={true} withTableBorder={true}
            highlightOnHover
        >
            <Table.Thead bg={colorScheme === "dark" ? "dark.8" : "gray.0"}>
                <Table.Tr>
                    <Table.Th rowSpan={2}>
                        {t("reports.table.seatNames")}
                    </Table.Th>
                    <Table.Th colSpan={3}>
                        {t("reports.table.salesTickets")}
                    </Table.Th>
                </Table.Tr>
                <Table.Tr>
                    <Table.Th>
                        {t("reports.table.quantity")}
                    </Table.Th>
                    <Table.Th>
                        {t("reports.table.price")}
                    </Table.Th>
                    <Table.Th>
                        {t("reports.table.sum")}
                    </Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {Object.keys(grouped).map((key, i) => {
                    const totals = grouped[key].reduce((acc, curr) => {
                        acc.totals_sum += curr.sum
                        acc.totals_quantity += curr.quantity
                        return acc;
                    }, {
                        totals_sum: 0,
                        totals_quantity: 0,
                    })

                    return (
                        <React.Fragment key={i}>
                            <Table.Tr>
                                <Table.Td colSpan={4}><i>{key}</i></Table.Td>
                            </Table.Tr>
                            {grouped[key].map((row, i) => {
                                return (
                                    <Table.Tr key={i}>
                                        <Table.Td pl={30}>
                                            {row.row_number}, <i>({row.seats})</i>
                                        </Table.Td>
                                        <Table.Td style={{ textAlign: "right" }}>
                                            {formatQuantity(row.quantity)}
                                        </Table.Td>
                                        <Table.Td style={{ textAlign: "center" }}>
                                            {formatAmount(row.price)}
                                        </Table.Td>
                                        <Table.Td style={{ textAlign: "center" }}>
                                            {formatAmount(row.sum)}
                                        </Table.Td>
                                    </Table.Tr>
                                )
                            })}
                            <Table.Tr>
                                <Table.Th pl={30}>{t("reports.table.total")}</Table.Th>
                                <Table.Th style={{ textAlign: "right" }}>{formatQuantity(totals.totals_quantity)}</Table.Th>
                                <Table.Th></Table.Th>
                                <Table.Th style={{ textAlign: "center" }}>{formatAmount(totals.totals_sum)}</Table.Th>
                            </Table.Tr>
                        </React.Fragment>
                    )
                })}
            </Table.Tbody>
            <Table.Tfoot bg={colorScheme === "dark" ? "dark.8" : "gray.0"} style={{ borderTop: "1px solid lightgray" }}>
                <Table.Tr>
                    <Table.Th>{t("reports.table.grand_total")}</Table.Th>
                    <Table.Th style={{ textAlign: "right" }}>{formatQuantity(totals.quantity)}</Table.Th>
                    <Table.Th></Table.Th>
                    <Table.Th style={{ textAlign: "center" }}>{formatAmount(totals.sum)}</Table.Th>
                </Table.Tr>
            </Table.Tfoot>
        </Table>
    );
};