export const ApiTags = [
    "Entity",
    "Event",
    "Hall",
    "Scheme",
    "User",
    "UserGroup",
    "Building",
    "ERROR",
    "Address",
    "BoxOffice",
    "Zone",
    "Booking",
    "EntityPermissions",
    "Analytics",
    "Invoice",
    "Seat",
    "EventContent",
    "EventType",
    "Receipt"
]