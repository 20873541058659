import {IBooking} from "../../../api/booking";
import {ITicket, useGetTicketsQuery} from "../../../api/event";
import {
    ActionIcon,
    Collapse,
    Flex,
    Loader,
    Paper,
    rem,
    Table,
    Text,
    useComputedColorScheme
} from "@mantine/core";
import {useTranslation} from "react-i18next";
import {LucideTicket, Printer, Undo2} from "lucide-react";
import React from "react";

type TProps = {
    inCollapse: { isOpen: boolean, bookingId?: number }
    booking: IBooking,
    onClickPrint: (ticket: ITicket) => void,
    isCashier: boolean,
    refundTicket: (ticketId: number) => void
}

const CollapsedContent = ({inCollapse, booking, onClickPrint, isCashier, refundTicket}: TProps) => {
    const computedColorScheme = useComputedColorScheme("dark", {
        getInitialValueInEffect: true
    });
    const {t} = useTranslation();
    const {data, isFetching} = useGetTicketsQuery({
        booking: inCollapse.bookingId
    }, {
        skip: !inCollapse.bookingId,
    })

    return (
        <Collapse in={inCollapse.isOpen}>
            {isFetching && (
                <div style={{height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Loader size={18} my={3}/>
                </div>
            )}
            <Paper shadow={"md"} my={20}>
                <Table highlightOnHover={true}
                       highlightOnHoverColor={computedColorScheme === 'dark' ? "gray.8" : "gray.2"}
                       verticalSpacing={"xs"}
                       horizontalSpacing={"md"}>
                    <Table.Tbody>
                        {data?.results?.map(ticket => {
                            const split = ticket.key.split(":");
                            const label = split[0];
                            const seatN = split[1];
                            const rowN = split[2];
                            return (
                                <Table.Tr key={ticket.id}>
                                    <Table.Td>
                                        <Flex align={'center'}>
                                            <LucideTicket size={18} color={'orange'}/>
                                            <Text size={"sm"} ml={20} mx={5}>
                                                {t(`sales.${label}`)}, &nbsp;
                                                {t("sales.row")} {seatN},
                                                &nbsp;{t("sales.seat")} {rowN}</Text>

                                        </Flex>
                                    </Table.Td>
                                    <Table.Td>
                                        <Text size={'sm'}>{ticket.amount} MDL</Text>
                                    </Table.Td>
                                    <Table.Td>
                                        <Text size={'sm'}
                                              c={ticket.status === 'sold' ? 'teal' : 'orange'}>
                                            {t(`orders.${ticket.status}`)} ({t(`sales.ticket_log.payment_type.${ticket.sale.payment_type}`)})
                                        </Text>
                                    </Table.Td>
                                    <Table.Td align={"right"}>
                                        {((booking.mode === "staff" || booking.mode === "admin") && ticket.status === 'sold') && (
                                            <ActionIcon variant={'transparent'} c={'blue'}
                                                        onClick={() => onClickPrint(ticket)}
                                            >
                                                <Printer style={{width: rem(22), height: rem(22)}}/>
                                            </ActionIcon>)}
                                        {(ticket.status === 'sold' && isCashier) && (
                                            <ActionIcon variant={'transparent'} c={'red'} ml={5}
                                                        onClick={() => refundTicket(ticket.id)}>
                                                <Undo2 style={{width: rem(22), height: rem(22)}}/>
                                            </ActionIcon>
                                        )}
                                    </Table.Td>
                                </Table.Tr>
                            )
                        })}
                    </Table.Tbody>
                </Table>
            </Paper>
        </Collapse>
    )
}

export default CollapsedContent