import { api } from "../app/api";
import {TText} from "../pages/sales/sales.slice";

interface User {
    id: string;
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
}

export interface IScheme {
    id?: number;
    name: string;
    author?: User;
    modifier?: User;
    building?: number;
}

interface IQueryResponse {
    count: number;
    next: any;
    previous: any;
    results: IScheme[];
}

interface IQueryParams {
    entity?: number;
    hall?: number;
    name?: string;
    exclude_state?: string,
    ordering?: string;
    page?: number;
    page_size?: number;
}

export interface ISchemeRead {
    id: number;
    name: string;
    hall_parts: {
        name: string
        rows: []
        scheme: number
    }[];
    data: {
        [key: string]: {
            place: number,
            rect: {
                x: string,
                y: string
            }
        }
    };
    properties: { text: TText }
}

export const schemeApi = api.injectEndpoints({
    endpoints: (build) => ({
        getSchemes: build.query<IQueryResponse, IQueryParams>({
            query: (params) => ({
                url: `/hall/admin/schemes/`,
                params: {
                    entity: params.entity,
                    hall: params.hall,
                    exclude_state: params.exclude_state
                }
            }),
            providesTags: (result, error, tag) => [{ type: "Scheme", tag }]
        }),
        readScheme: build.query<ISchemeRead, any>({
            query: (params) => ({
                url: `/hall/admin/schemes/${params.id}/`
            }),
            providesTags: (result, error, tag) => [{ type: "Scheme", tag }]
        }),
        createScheme: build.mutation<any, any>({
            query: (payload) => ({
                url: "/scheme/create",
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{ type: "Scheme" }]
        }),
        updateScheme: build.mutation<any, any>({
            query: (payload) => ({
                url: "/scheme/create",
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{ type: "Scheme" }]
        }),
        removeScheme: build.mutation<any, any>({
            query: (payload) => ({
                url: `/hall/admin/schemes/${payload.id}`,
                method: "DELETE"
            }),
            invalidatesTags: [{ type: "Scheme" }]
        })
    })
});

export const {
    useCreateSchemeMutation,
    useUpdateSchemeMutation,
    useRemoveSchemeMutation,
    useReadSchemeQuery,
    useGetSchemesQuery,
    useLazyReadSchemeQuery
} = schemeApi;
