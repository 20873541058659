import {Button, Card, Flex, Group, LoadingOverlay, Modal, Radio} from "@mantine/core";
import {useConfirmationModal} from "../../../hooks/useConfirmationModal";
import React, {useEffect, useState} from "react";
import {isErrorWithMessage, showErrorMessages} from "../../../app/api";
import {IReceipt, useChangeTypeReceiptMutation, useLazyReadReceiptQuery} from "../../../api/receipt";
import {notification} from "../../../utils/notification";
import {IBooking} from "../../../api/booking";
import {useLazyGetTicketsQuery} from "../../../api/event";

type TProps = {
    opened: boolean,
    booking?: IBooking | null,
    onClose: () => void
}
const ChangePaymentType = ({opened, booking, onClose}: TProps) => {
    const [ConfirmModal, confirmChangeType] = useConfirmationModal({});
    const [readReceipt] = useLazyReadReceiptQuery();
    const [getTickets] = useLazyGetTicketsQuery();
    const [changePaymentType] = useChangeTypeReceiptMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [receiptData, setReceipt] = useState<IReceipt>()
    useEffect(() => {
        if (opened && booking) {
            setIsLoading(true)
            getTickets({booking: booking.id}).then(res => {
                if (res.data?.results && res.data?.results.length > 0) {
                    if (res.data.results[0].sale_document?.id) {
                        readReceipt({id: res.data.results[0].sale_document?.id}).then(res => {
                            setReceipt(res.data)
                            setIsLoading(false)
                        }).catch(err => {
                            setIsLoading(false)
                        })
                        return;
                    }
                    setIsLoading(false)
                }
                setIsLoading(false)
            }).catch(err => {
                setIsLoading(false)
            })

        }
    }, [opened]);


    async function onClickSave() {
        try {
            const isConfirmed = await confirmChangeType()

            if (!isConfirmed) return;

            if (receiptData) {
                await changePaymentType({payment_type: receiptData.payment_type, id: receiptData.id}).unwrap();
            }
            onClose();
            notification({type: "success", title: "Тип продажи сохранен"});
        } catch (e) {
            console.log(e);
            if (isErrorWithMessage(e)) {
                showErrorMessages(e.data);
            }
        }
    }

    async function onChangePaymentType(paymentType: 'cash' | 'card') {
        try {
            if (receiptData) {
                setReceipt({...receiptData, payment_type: paymentType})
            }
        } catch (e) {
            if (isErrorWithMessage(e)) {
                showErrorMessages(e.data);
            }
        }
    }

    if (isLoading && !receiptData) {
        return <LoadingOverlay visible={true}/>
    }
    return (
        <>
            <Modal
                title={'Сменить тип продажи'}
                size={"xl"} opened={opened}
                onClose={onClose} centered
            >

                <Card shadow={"md"} pb={25} my={10} mb={20}>
                    <Flex justify={"center"}>
                        <Radio.Group
                            name="favoriteFramework"
                            withAsterisk
                            onChange={onChangePaymentType}
                            value={receiptData?.payment_type}
                        >
                            <Group mt="xs">
                                <Radio value="card" label="Карта"/>
                                <Radio value="cash" label="Наличные"/>
                            </Group>
                        </Radio.Group>
                    </Flex>
                </Card>

                <Flex justify="flex-end">
                    <Button onClick={onClickSave}>
                        Сохранить
                    </Button>
                </Flex>
            </Modal>
            <ConfirmModal/>
        </>
    )
}

export default ChangePaymentType;