import { useRef } from "react";
import { Box, LoadingOverlay, Paper, Table, Text, useMantineColorScheme } from "@mantine/core";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
import { TBoxOfficeSalesResponse, useBoxOfficeSalesQuery } from "../../../api/analytics";
import { useAppSelector } from "../../../app/hooks";
import ReportHeader from "../ReportActions";
import dayjs from "dayjs";
import { formatAmount, formatQuantity } from "../report_utils";
import ReportPrint from "../ReportPrint";

const ByPeriod = () => {
    const { t } = useTranslation();
    const reportRef = useRef(null);
    const selectedEvent = useAppSelector(state => state.reports.selectedEvent);
    const dates = useAppSelector(state => state.reports.datesRange);
    const byCashier = useAppSelector(state => state.reports.byCashier);
    const isEqualDates = dayjs(dates[0]).format("DD-MM-YYYY") === dayjs(dates[1]).format("DD-MM-YYYY");
    const { data: report, isFetching } = useBoxOfficeSalesQuery({
        event: selectedEvent || 0,
        date_lte: dates[1] ? dayjs(dates[1]).format('YYYY-MM-DD') : undefined,
        date_gte: dates[0] ? dayjs(dates[0]).format('YYYY-MM-DD') : undefined,
        by_cashier: byCashier
    }, {
        refetchOnMountOrArgChange: true,
        skip: !selectedEvent
    });
    const handlePrint = useReactToPrint({
        content: () => reportRef.current,
        copyStyles: true,
        pageStyle: `@page {
           margin: 10mm;
           size: A4 landscape;
        }`
    });

    return (
        <Box pos={"relative"}>
            <Paper p={10}>
                <ReportHeader
                    showEventSelect
                    showPeriodSelect
                    showByCashier
                    dateType={"range"}
                />
            </Paper>
            {selectedEvent && (
                <Paper mt={10} pt={5} p={20} ref={reportRef} pos={"relative"}>
                    <ReportPrint handlePrint={handlePrint} />
                    <Box ta={"center"} mb={30}>
                        <Text size={"30px"} pb={20}>{t("reports.ticket-sales.report")}</Text>
                        <Text
                            size={"lg"}>
                            {t("reports.by-period.textHead")} {isEqualDates ? t("reports.by-period.toDate") : t("reports.by-period.period")}
                            <Text fw={600} span c={'blue.9'}> {isEqualDates ? dates[0] && dayjs(dates[0]).format("DD-MM-YYYY") : (dates[0] && dates[1]) && dayjs(dates[0]).format("DD-MM-YYYY") + ' - ' + dayjs(dates[1]).format("DD-MM-YYYY")}</Text>
                        </Text>
                        <Text size={"lg"}>{t("reports.ticket-sales.textHead2")}</Text>
                        <Text c={'blue.9'} size={'lg'} fw={600}>{report?.event.name} {dayjs(report?.event.start).format("DD.MM.YYYY HH:mm")}</Text>
                    </Box>

                    <Box my={20}>
                        <ByPeriodTable data={report} />
                    </Box>
                </Paper>
            )}

            <LoadingOverlay visible={isFetching} />
        </Box>
    );
};

export default ByPeriod;

const ByPeriodTable = (props: { data?: TBoxOfficeSalesResponse }) => {
    const { t } = useTranslation();
    const { colorScheme } = useMantineColorScheme();
    const byCashier = useAppSelector(state => state.reports.byCashier);
    const totals = props.data?.rows.reduce((acc, curr) => {
        acc = {
            sold_amount: acc.sold_amount += curr.sold_amount,
            sold_quantity: acc.sold_quantity += curr.sold_quantity,
            refunded_quantity: acc.refunded_quantity += curr.refunded_quantity,
            sold_realised: acc.sold_quantity - acc.refunded_quantity
        };
        return acc;
    }, {
        sold_amount: 0,
        sold_quantity: 0,
        refunded_quantity: 0,
        sold_realised: 0
    });

    return (
        <Table withTableBorder withColumnBorders withRowBorders striped highlightOnHover={true}>
            <Table.Thead bg={colorScheme === "dark" ? "dark.8" : "gray.0"}>
                
                <Table.Tr>
                    <Table.Th>
                        {t("reports.table.soldDate")}
                    </Table.Th>
                    {byCashier && (<Table.Th>
                        {t("reports.table.cashier")}
                    </Table.Th>)}
                    <Table.Th>
                    {t("reports.table.sold")}
                    </Table.Th>
                    <Table.Th>
                    {t("reports.table.refunded")}
                    </Table.Th>
                    <Table.Th>
                        {t("reports.table.realised")}
                    </Table.Th>
                    <Table.Th>
                        {t("reports.table.sum")}
                    </Table.Th>
                </Table.Tr>

            </Table.Thead>
            <Table.Tbody>
                {props.data?.rows.map((item, index) => {
                    return (
                        <Table.Tr key={index}>
                            <Table.Td>
                                {dayjs(item.date).format("DD-MM-YYYY")}
                            </Table.Td>
                            {byCashier && (<Table.Td>
                                {item.cashier}
                            </Table.Td>)}
                            <Table.Td style={{ textAlign: "center" }}>
                                {formatQuantity(item.sold_quantity)}
                            </Table.Td>
                            <Table.Td style={{ textAlign: "center" }}>
                                {formatQuantity(item.refunded_quantity)}
                            </Table.Td>
                            <Table.Td style={{ textAlign: "center" }}>
                                {formatQuantity(item.sold_quantity - item.refunded_quantity)}
                            </Table.Td>
                            <Table.Td style={{ textAlign: "right" }}>
                                {formatAmount(item.sold_amount)}
                            </Table.Td>
                        </Table.Tr>
                    );
                })}
            </Table.Tbody>
            <Table.Tfoot bg={colorScheme === "dark" ? "dark.8" : "gray.0"} style={{ borderTop: "1px solid lightgray" }}>
                <Table.Tr>
                    <Table.Th colSpan={byCashier ? 2 : 1}>
                        {t("reports.table.total")}
                    </Table.Th>
                    <Table.Th style={{ textAlign: "center" }}>
                        {formatQuantity(totals?.sold_quantity || 0)}
                    </Table.Th>
                    <Table.Th style={{ textAlign: "center" }}>
                        {formatQuantity(totals?.refunded_quantity || 0)}
                    </Table.Th>
                    <Table.Th style={{ textAlign: "center" }}>
                        {formatQuantity(totals?.sold_realised || 0)}
                    </Table.Th>
                    <Table.Th style={{ textAlign: "right" }}>
                        {formatAmount(totals?.sold_amount || 0)}
                    </Table.Th>
                </Table.Tr>
            </Table.Tfoot>
        </Table>
    );
};
