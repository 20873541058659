import { useLocation, useNavigate } from "react-router-dom";
import { Box, Divider, Flex, Paper, Text, useMantineColorScheme } from "@mantine/core";
import dayjs from "dayjs";
import {IEventRead, useGetEventsQuery} from "../api/event";
import { AlarmClock } from "lucide-react";
import EventInputSelect from "./EventInputSelect";
import {roleCheck} from "../utils/roleCheck";
import {useAppSelector} from "../app/hooks";

const EventInfo = ({ event, resetSelectedSeats }: { event: IEventRead, resetSelectedSeats?: () => void }) => {
    const { colorScheme } = useMantineColorScheme();
    const navigate = useNavigate();
    const location = useLocation();
    const roles = useAppSelector(state => state.auth.user.role);
    const { data = { results: [] } } = useGetEventsQuery({
        page: 1,
        page_size: 9999,
        ordering: "start",
        start_gte: location.state ? location.state.eventFilter.start_gte : undefined,
        finish_lte: location.state ? location.state.eventFilter.finish_lte : undefined,
        cashier_accessible: roleCheck(roles, ["cashiers"])
    });

    function onChangeEvent(v: string) {
        const currentLocation = location.pathname.split("/").slice(0, location.pathname.split("/").length - 1).join("/");
        navigate(currentLocation + "/" + v);
        resetSelectedSeats && resetSelectedSeats();
    }

    return (
        <>
            <Paper style={{ borderRadius: 0, textAlign: "center" }} pt={5} pb={10}
                   bg={colorScheme === "light" ? "#f7f7f7" : "dark.6"}
            >
                <Box px={5}>
                    <EventInputSelect
                        onChange={onChangeEvent}
                        value={String(event.id)}
                        data={data.results}
                    />
                </Box>

                <Flex w={"100%"} py={10} px={7}>
                    <div>
                        <img src={import.meta.env.VITE_BACKEND_URL + event.qrcode} alt={"qr"}
                             width={60} height={60}
                        />
                    </div>
                    <Flex direction={"column"} align={"center"} pl={10}>
                        <Text fw={400}>{dayjs(event.start).format("DD MMMM YYYY")}</Text>
                        <Flex align={"center"}>
                            <AlarmClock size={15} color={"orange"} />
                            <Text fw={400} span ml={3}>{dayjs(event.start).format("HH:mm")}</Text>
                        </Flex>

                    </Flex>
                </Flex>
                <Text pb={10} size={"sm"} fw={500}>{event.building?.name}</Text>

            </Paper>
            <Divider />
        </>
    );
};

export default EventInfo;
