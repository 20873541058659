import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { LoadingOverlay } from "@mantine/core";
import merge from "deepmerge-json";
//REDUX
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
    onSeatClick,
    reset, setData, setPrices,
    setTotalSeats,
    setUpdatedSeats,
    toggleLoading, setText
} from "../prices.slice";
//API
import { useLazyGetSeatsQuery } from "../../../../api/zone";
import { useLazyReadSchemeQuery } from "../../../../api/scheme";
import Schema from "../../../../components/Schema/Schema";

const Seats = () => {
    const params = useParams();

    //API
    const [getSeats] = useLazyGetSeatsQuery();
    const [readScheme] = useLazyReadSchemeQuery();

    //redux
    const dispatch = useAppDispatch();
    const data = useAppSelector(state => state.schema.data);
    const text = useAppSelector(state => state.schema.text);
    const zones = useAppSelector(state => state.schema.zones);
    const schemeId = useAppSelector(state => state.schema.event.scheme);
    const loading = useAppSelector(state => state.schema.loading);

    useEffect(() => {
        async function getSeatsAndScheme() {
            try {
                if (params.id && schemeId) {
                    dispatch(toggleLoading());
                    const eventSeats = await getSeats({ id: params.id }).unwrap();
                    const scheme = await readScheme({ id: schemeId }).unwrap();

                    //count initial total and priced seats
                    dispatch(setTotalSeats(Object.keys(scheme.data).length));

                    //performance test
                    // console.log('...start', dayjs(new Date()).format('ss.sss'))
                    // console.log(merge(scheme.data, eventSeats))
                    // console.log('...finish', dayjs(new Date()).format('ss.sss'))
                    dispatch(setText(scheme.properties.text))
                    dispatch(setData(
                        { data: merge(scheme.data, eventSeats) } //initialData
                    ));
                    dispatch(toggleLoading());
                }
            } catch (e) {
                dispatch(toggleLoading());
            }
        }

        getSeatsAndScheme();

    }, [params.id, schemeId]);

    useEffect(() => {
        return () => {
            dispatch(reset());
        };

    }, []);

    const onSeatClickEvent = useCallback((seatId: number, key: string, zone?: number | null, status?: "free" | "reserved" | "sold" | "disabled") => {
        if (status === "sold" || status === "reserved") return;
        dispatch(onSeatClick({ seatId, key }));
    }, []);

    const onSelectionArea = useCallback((tmpSelection: string[], collectedSeats: { id: number, status: string }[]) => {
        dispatch(setPrices({ tmpSelection }));
        //set zones in data
        dispatch(setUpdatedSeats({ collectedSeats: collectedSeats }));
    }, []);

    return (
        <div style={{ position: "relative", userSelect: "none" }}>
            <LoadingOverlay visible={loading} />
            <Schema
                zones={zones}
                data={data}
                onSeatClick={onSeatClickEvent}
                onSelectionArea={onSelectionArea}
                text={text}
                type={"price"}
            />
        </div>
    );
};

export default Seats;
